import { Router, useRouter } from 'next/router';
import Head from 'next/head';
import { Trans, appWithTranslation, useTranslation } from 'next-i18next';
import NProgress from 'nprogress';
import { Toaster } from 'react-hot-toast';
import { AuthProvider } from '@wbk/auth';
import { QueryProvider } from '@wbk/api/hooks';
import { UiProvider } from '@wbk/ui';
import { WbkTicketingProvider } from '@wbk/ticketing';
import { AnalyticsProvider } from '@wbk/analytics';
import { WbkQueueProvider } from '@wbk/queue';
import Link from 'next/link';
import DefaultSeo from '@/components/headers/seo/DefaultSeo';
import SocialJsonLd from '@/components/headers/seo/SocialJsonLd';
import Layout from '@/components/layout';
import OfflineState from '@/components/common/OfflineState';
import CookieConsent from '@/components/common/CookieConsent';
import InitialLogoOpener from '@/components/ui/InitialLogoOpener';
import ErrorBoundary from '@/components/error/ErrorBoundary';
import { POSTER_1X1_FADED, POSTER_3X1_FADED } from '../constants/fallbackLogo';
import type { AppProps } from 'next/app';
import '../styles/globals.css';
import { gtmTracker } from '../../trackers.config';
import '../../api.config';
import '../../logger.config';

/**
 * Ng Progress
 */
const handleRouteStart = () => NProgress.start();
const handleRouteDone = () => NProgress.done();
Router.events.on('routeChangeStart', handleRouteStart);
Router.events.on('routeChangeComplete', handleRouteDone);
Router.events.on('routeChangeError', handleRouteDone);

function App({ Component, pageProps }: AppProps<LayoutProps>) {
  const { locale, pathname, push, replace } = useRouter();
  const { t } = useTranslation();

  return (
    <>
      <Head>
        <meta name='viewport' content='minimum-scale=1, initial-scale=1, width=device-width' />
      </Head>
      <ErrorBoundary>
        <QueryProvider>
          <UiProvider
            locale={locale!}
            i18n={{ useTranslation, Trans }}
            imgFallbacks={{ '1x1': POSTER_1X1_FADED, '3x1': POSTER_3X1_FADED }}
          >
            <AnalyticsProvider trackers={[gtmTracker]} currentLocation={pathname}>
              <AuthProvider
                locale={locale!}
                autoInit={false}
                appSource={process.env.NEXT_PUBLIC_APP_SOURCE!}
                i18n={{ useTranslation, Trans }}
                router={{ Link, push, replace }}
                newsLetter={{ organizationName: t('common:title') }}
                poweredBy
              >
                <WbkQueueProvider locale={locale!} i18n={{ useTranslation, Trans }}>
                  <WbkTicketingProvider
                    locale={locale!}
                    bookingSource={process.env.NEXT_PUBLIC_APP_SOURCE!}
                    seatsIO={{
                      workspace: process.env.NEXT_PUBLIC_SEATIO_WORKSPACE_KEY,
                    }}
                    resell={{ terms: t('common:agree_resell') }}
                    i18n={{ useTranslation, Trans }}
                    router={{ Link, push, replace }}
                    wallet={false}
                  >
                    <SocialJsonLd />
                    <DefaultSeo />
                    <Toaster position='top-center' />
                    <InitialLogoOpener />
                    <Layout {...pageProps}>
                      <Component {...pageProps} />
                    </Layout>
                    <CookieConsent />
                    {/* Offline notice */}
                    <OfflineState />
                  </WbkTicketingProvider>
                </WbkQueueProvider>
              </AuthProvider>
            </AnalyticsProvider>
          </UiProvider>
        </QueryProvider>
      </ErrorBoundary>
    </>
  );
}

export default appWithTranslation(App);
